import * as React from "react";
import { graphql } from "gatsby";
import { CheckIcon } from "@heroicons/react/outline";
import { Header, Footer } from "../components/layout/index";
import { Seo } from "../components/Seo";
import { Cta } from "../components/sections/index";
import { Link } from "../components/atoms/Link";

const tiers = [
  {
    title: "Requérant individuel",
    price: "Gratuit",
    description: "Solliciter des avis médicaux",
    featuresTitle: "Fonctionnalités",
    features: [
      "Solution complète de téléexpertise",
      "Intégration Vitale et e-CPS ",
      "Applications iOS et Android",
      "Partage sécurisé de documents médicaux",
      "Création automatique de compte rendu",
      "Guide de cotation",
      "Interfaçage avec des logiciels métiers",
    ],
    cta: "S'inscrire",
    href: "https://app.omnidoc.fr/register",
  },
  {
    title: "Requis individuel",
    price: "Gratuit",
    description: "Répondre à vos correspondants",
    featuresTitle: "Fonctionnalités",
    features: [
      "Solution complète de téléexpertise",
      "Intégration Vitale et e-CPS ",
      "Applications iOS et Android",
      "Lecteurs photos, vidéos et DICOM intégrés",
      "Création automatique de compte rendu",
      "Guide de cotation",
      "Interfaçage avec des logiciels métiers",
      "Instructions médicales et gestion des demandes",
      "Gestion de la visibilité et des disponibilités",
    ],
    cta: "S'inscrire",
    href: "https://app.omnidoc.fr/register",
  },
  {
    title: "Organisation",
    price: "Sur devis",
    description: "Organiser la téléexpertise sur votre territoire",
    featuresTitle: "Fonctionnalités",
    features: [
      <span className="font-semibold">
        Toutes les fonctionnalités{" "}
        <span className="italic">Requis individuel</span>
      </span>,
      "Mise en place de lignes d'avis et contrôle du projet médical",
      "Page de présentation dédiée",
      "Planning de garde et outils de coordination",
      "Questionnaire personnalisable",
      "Outils de suivi et d'évaluation",
      "Accompagnement personnalisé",
      "Gestion du circuit administratif",
      "Possibilité d'interfaçage avec vos SI",
    ],
    cta: "Nous contacter",
    href: "/demander-une-demo",
  },
];

export default function Pricing({ data }) {
  const page = (data || {}).mainPage;

  return (
    <>
      <Seo title="Tarifs - Solution de téléexpertise et de e-RCP" />
      <Header />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="pb-10 sm:pb-18">
            <div className="text-center">
              <h1 className="text-base font-semibold text-action-600 tracking-wide uppercase">
                Tarifs
              </h1>
              <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight">
                Des offres adaptées à votre activité
              </p>
              <p className="max-w-2xl mt-5 mx-auto text-xl text-gray-500">
                La solution Omnidoc est totalement gratuite pour une utilisation
                individuelle et propose des fonctionnalités avancées pour les
                organisations.
              </p>
            </div>
          </div>
          <div className="mt-12 space-y-12 max-w-lg mx-auto xl:mt-16 xl:space-y-0 xl:grid xl:grid-cols-3 xl:gap-6 xl:max-w-none xl:mx-0">
            {tiers.map(tier => (
              <div
                key={tier.title}
                className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div className="p-6">
                  <h2 className="text-xl leading-6 font-medium text-gray-900">
                    {tier.title}
                  </h2>
                  <p className="mt-4 text-base text-gray-500">
                    {tier.description}
                  </p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      {tier.price}
                    </span>
                  </p>
                  <Link
                    to={tier.href}
                    className="mt-8 block w-full bg-action-600 border border-transparent rounded-md py-2 text-base font-medium text-white text-center over:bg-action-700"
                  >
                    {tier.cta}
                  </Link>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    {tier.featuresTitle}
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.features.map(feature => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-base text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {page.ctaBanner && (
        <Cta
          titleLine1={page.ctaBanner.titleLine1}
          titleLine2={page.ctaBanner.titleLine2}
          description={page.ctaBanner.description}
          buttonText={page.ctaBanner.buttonText}
          url={page.ctaBanner.url}
        />
      )}
      <Footer />
    </>
  );
}

export const query = graphql`
  query PricingIndexPageQuery {
    mainPage: sanityMainPage(_id: { regex: "/(drafts.|)mainPage/" }) {
      ctaBanner {
        titleLine1
        titleLine2
        description
        buttonText
        url
      }
    }
  }
`;
